/* fonts */
@font-face {
  font-family: 'Optima';
  src: local('Optima'), url(../fonts/OPTIMA.woff) format('woff')
}

@font-face {
  font-family: 'Grotesk';
  font-style: normal;
  font-weight: normal;
  src: local('Nimbus'), url(../fonts/NimbusSanL-Reg-webfont.woff) format('woff')
}

@font-face {
  font-family: 'Grotesk';
  font-style: italic;
  font-weight: normal;
  src: local('Nimbus'), url(../fonts/NimbusSanL-RegIta-webfont.woff) format('woff')
}

@font-face {
  font-family: 'Grotesk';
  font-style: normal;
  font-weight: 600;
  src: local('Nimbus'), url(../fonts/NimbusSanL-Bol-webfont.woff) format('woff')
}

body {
  font-family: 'Grotesk', sans-serif;
}

/* these are still used by the ULs for search results */

ul.groupList li {
  display: inline-block;
  padding: 10px;
  background: #eee;
  border: 1px solid #222;
  font-size: small;
  margin: 10px;
}

ul.groupTreatmentsList {
  padding-left: 0px;
}

.groupSortItem {
  list-style-type: none;
  list-style-position: inside;
  padding: 3px 6px;
  margin: 4px;
  background: #DDEAFF;
  border: 1px solid #CCCCAA;
  font-size: 16px;
}

.planSortItem {
  list-style-type: none;
  list-style-position: inside;
  width: 350px;
  padding: 3px 6px;
  margin: 4px;
  background: #DDEAFF;
  border: 1px solid #CCCCAA;
  font-size: 16px;
  display: inline-block;
}

.groupSelector {
  background: #DDEAFF;
}

.treatmentSortItem {
  list-style-type: none;
  list-style-position: inside;
  padding: 3px 6px;
  margin: 4px;
  background: #FFFFEA;
  border: 1px solid #CCCCAA;
  font-size: 16px;
}

.treatmentSelector {
  background: #FFFFEA;
}

ul.treatmentList li {
  display: inline-block;
  padding: 10px;
  background: #eee;
  font-size: small;
  margin: 10px;
}

/* This image-sizing should probably just become inline */

.trashIcon {
  font-size: 72px !important;
  color: #aaaaaa
}


/* We probably want to keep these since they format programData nicely, but I wasn't sure if they
    should go inline, in a ProgramData.css, or stay here.
 */
.programDataTableDiv {
  background: #f3f3f3;
}
.programDataTable {
  margin: 12px;
}

.programTableLabel {
  font-size: 10px;
  font-weight: bold;
}

.programTableDeleteButton {
  font-size: 8px;
  padding: 4px;
}

.programData {
  font-size: 12px;
}

.programWaveforms {
  font-family: monospace;
}


/* Global settings */

::placeholder {   /* color for input placeholder text */
  color: #d3d3d3 !important;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}

.nav-item {
  font-family: Optima;
  color: black;
  font-weight: bold;
}

html,body {
  height: 100%;
}

/* Basic text styles */

.userInput {
  font-family: "Lucida Console", Courier, monospace;
  color: black;
  font-size: 14px;
}

.userData {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-size: 14px;
}

.inputLabel {
  font-family: Arial, Helvetica, sans-serif;
  color: darkblue;
  font-size: 14px;
  font-style: italic;
  text-align: end;
  vertical-align: top;
  padding-top: 12px;
  padding-right: 14px;
}

.listHeader {
  font-family: Arial, Helvetica, sans-serif;
  color: darkblue;
  font-size: 14px;
  font-style: italic;
  text-align: start;
  vertical-align: top;
}

.listHeaderRow {
  background: #E1E1FF;
  cursor: pointer;
}

.userList {
  line-height: 1.0;
}

.userListName {
  font-size: 13px;
  font-weight: bold;
}

.userListEmail {
  font-size: 12px;
  font-style: italic;
}

.referenceList {
  font-size: 11px;
  font-style: italic;
}

.warnText {
  font-size: 15px;
  font-style: italic;
  color: #856404;
  background: #fff4bf;
}

/* Misc */

.switchOnLabel {
  color: black;
  font-weight: bold;
}

.switchOffLabel {
  color: gray;
}

.selected {
  background: #FDFDFB
}

.selectionBar {
  background: #F4F4FF;
  width: 100%;
  position: absolute;
  overflow-y: scroll;
  height: 100vh;
}

.Masthead {
  background: #F4F4FF;
}

.editorPane {
  background: #FDFDFB;
  width: 100%;
  position: absolute;
  overflow-y: scroll;
  height: 100vh;
}

.referencePane {
  position: absolute;
  overflow-y: scroll;
  height: 100vh;
}

.referenceTable {
  background: #ffffcc;
}

.pageContainer {
  overflow: hidden;
  position: fixed;
}

.splitRow {
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.buttonRow {
}

.editorImage {
  border: 1px solid #CCCCAA;
  display: block;
  object-fit: cover;
}

.localeSelectorMenuID {
  display: inline-block;
  width: 60px;
  font-weight: bold;
}

.localeSelectorMenuName {

}

.filterListTable {
  border-spacing: 12px;
}

.filterItem {
  display: inline-block;
  margin: 6px;
  padding: 4px;
  background: #ffdf7e;
  border-radius: 10px;
}

.tagListTable {
  border-spacing: 12px;
}

.tagItem {
  display: inline-block;
  margin: 6px;
  padding: 4px;
  background: #ffdf7e;
  border-radius: 10px;
}

/* Generic classes */

.home {
  margin: 36px;
}

.itemTitle {
  font-size: 20px;
}

.itemAction { /* buttons for item detail actions */
  margin-right: 12px;
}

.homeText {
  font-family: Optima, sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.homeSubtext {
  font-size: 16px;
  font-style: italic;
}

.glyphIcon {
  font-size: 18px !important;
  cursor: pointer;
  margin-left: 8px !important;
}

.warnIcon {
  color: #c69500;
}

.homeIcon {
  font-size: 36px !important;
  margin-bottom: 24px;
  color: cornflowerblue;
}

.copyIcon {
  font-size: 18px !important;
  display: inline-block;
  cursor: pointer;
  margin-left: 6px;
  margin-right: 6px;
  color: dimgray;
}

.sortArrow {
  margin-right: 0px;
  color: cornflowerblue;
}

.addButton {
  margin-top: 12px;
  margin-left: 30px;
  margin-bottom: 12px;
}

.removeIcon {
  color: red;
}

.addIcon {
  font-size: 18px !important;
  color: green;
}

.alertIcon {
  color: orange;
}

.navIcon {
  color: cornflowerblue;
  font-size: 18px !important;
}

.helpText {
  margin-top: 5px;
  font-size: 11px;
  padding: 3px;
}

.helpPopover {
  background: #ffffcc !important;
}

.hiddenIcon {
  color: transparent !important;
}