.statusLabel {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
}

.statusDetail {
    font-size: 14px;
    color: red;
}

.statusInterval {
    font-size: 14px;
    font-style: italic;
    color: dimgray;
}

.ingestingStatus {
    color: #ba8b00;
}

.processingStatus {
    color: #ba8b00;
}

.deployingStatus {
    color: #ba8b00;
}

.activeStatus {
    color: green;
}

.inactiveStatus {
    color: dimgray;
}

.videoUrl {
    display: inline-block;
    color: dimgray;
    font-size: 12px;
}

.statusIcon {
    font-size: 14px !important;
}

.statusIconYellow {
    color: #ba8b00;
}

.statusIconRed {
    color: red;
}

.statusIconGreen {
    color: green;
}
