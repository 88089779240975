.mastheadLoggedInUser {
    font-family: 'Roboto Mono', monospace;
    color: dimgray;
    font-weight: bold;
    font-size: 13px;
    display: inline-block;
}

.mastheadLabel {
    font-family: Optima;
}
