
div.login p {
    text-align: center;
}

div.login div.logoContainer {
    text-align: center;
}

div.login img.logo {
    width: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
}

div.login .label {
    text-align: right;
}

div.login input {
    width: 175px;
}

div.login .row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
}

div.login .label {
    padding-left: 0;
    padding-right: 5px;
}

div.login .value {
    padding-left: 0;
    padding-right: 0;
}

div.login .loginSubmitContainer {
    align-items: center;
    justify-content: center;
    text-align: center;
}

div.login #submitButton {
    margin-top: 10px;
}
