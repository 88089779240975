.treatmentListHeader {
    font-size: 18px;
    font-style: italic;
    margin-bottom: 12px;
    color: darkblue;
    height: 50px;
}

.treatmentListGroup {
    font-size: 12px;
    font-style: italic;
    color: darkblue;
    background: #ffffcc;
}

.treatmentListTreatment {
    font-size: 14px;
    font-weight: bold;
    background: #ffffcc;
}
